<template>
  <div class="card"
       :class="[lead.disable_accept == 1 && 'card--inactive', isExpired && 'card--expired']"
  >
    <div class="card__header">
      <LeadsStatus
        @expired="isExpired = true"
        :time="lead.expiry_date_time"
      />
      <h5 class="tw-font-bold tw-text-sm tw-mr-2">{{lead.container_id}}</h5>
      <h5 :title="lead.suburb +' '+ lead.postcode" class="header__title tw-text-black-gray">{{ lead.suburb }} {{lead.postcode}}</h5>
      <div class="header__info" @click="showAccept = true">
        <svg-icon name="solid/info-circle"/>
      </div>
      <div @click="toggleMenu" class="dropdown_menu">
        <svg-icon name="regular/ellipsis-h"/>
        <SimpleDropdown v-if="menuIsOpened" @close="closeMenu">
          <div class="reject" @click="showReject = true"><svg-icon name="solid/times"/><div>Reject</div></div>
        </SimpleDropdown>
      </div>
    </div>
    <div class="card__content">
      <div class="content__elements">
        <!-- <div class="d-flex"> -->
        <div class="tw-grid tw-gap-y-2" style="grid-template-columns: 1fr 150px;">
          <div class="d-flex align-items-center">
            <svg-icon name="solid/coins"/>
            Estimated Price: ${{ priceCalc(lead.amount) }}
          </div>
          <div class="d-flex align-items-center">
            <svg-icon name="solid/layer-group"/>
            Number of items: {{ lead.number_of_items }}
          </div>
        <!-- </div> -->
        <!-- <div class="d-flex"> -->
          <div class="d-flex align-items-center">
            <svg-icon name="regular/tools"/>
            Installation: {{ $filters.formatMoney(lead.installation_fee) }}
          </div>
          <div class="d-flex align-items-center">
            <svg-icon name="solid/coins"/>
            Profit(Est.): {{ $filters.formatMoney(lead.profit_amt) }}
          </div>

          <div v-if="lead?.lead?.customer_details?.display_name" class="d-flex align-items-center">
            <svg-icon name="solid/user"/>
            {{ lead?.lead?.customer_details?.display_name }}
          </div>

          <div v-if="lead?.lead?.customer_details?.phone" class="d-flex align-items-center">
            <svg-icon name="solid/phone"/>
            {{ lead?.lead?.customer_details?.phone }}
          </div>

          <div v-if="lead?.lead?.customer_details" class="d-flex align-items-center tw-col-span-full">
            <svg-icon name="solid/globe"/>
            {{ lead?.lead?.customer_details?.address }} {{ lead?.lead?.customer_details?.suburb }} {{ lead?.lead?.customer_details?.state_details?.code }} {{ lead?.lead?.customer_details?.postcode }}
          </div>

          <div v-if="lead?.lead?.customer_details?.email" class="d-flex align-items-center tw-col-span-full">
            <svg-icon name="solid/envelope"/>
            {{ lead?.lead?.customer_details?.email }}
          </div>
        </div>
        <!-- </div> -->
      </div>
      <ButtonMain class="content__button" @click="showAccept = true">
      </ButtonMain>
      <LeadsAccept v-if="showAccept" :id="lead.id" @close="showAccept = false"/>
      <LeadsReject v-if="showReject" :id="lead.id" @close="showReject = false"/>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import ButtonMain from "@/components/buttons/ButtonMain";
import LeadsAccept from "@/components/leads/LeadsAccept";
import LeadsReject from "@/components/leads/LeadsReject";
import SimpleDropdown from "@/components/dropdown/SimpleDropdown";
import DropdownMenu from "@/components/dropdown/DropdownMenu";
import {changePriceForm} from '../../helpers/aroundPrice';
import {formatDate, dateCalcFormat, dateRemainFormat} from "../../helpers/datetime";
import LeadsStatus from "@/components/leads/LeadsStatus";

export default {
  name: 'LeadsCard',
  props: ['lead'],
  components: {
    LeadsStatus,
    ButtonMain,
    SimpleDropdown,
    LeadsAccept,
    LeadsReject,
    DropdownMenu
  },
  setup() {
    const showReject = ref(false)
    const showAccept = ref(false)
    const menuIsOpened = ref(false)
    const isExpired = ref(false)
    let toggleMenu = () => {
      menuIsOpened.value =! menuIsOpened.value
    }
    let closeMenu = () => {
      menuIsOpened.value = false
    }
    let priceCalc = (price) => {
      return changePriceForm(price);
    }
    let dateCalc = (date) => {
      return date
    }
    let dateFormat = (date) => {
      return dateCalcFormat(date)
    }
    return {
      closeMenu,
      menuIsOpened,
      priceCalc,
      dateCalc,
      dateFormat,
      toggleMenu,
      showAccept,
      showReject,
      isExpired
    }
  }
};
</script>


<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 360px;
  border: 1px solid rgba(28, 40, 51, 0.2);
  border-radius: 2px;
  height: auto;
  min-height: 151px;
  position: relative;
  &:before {
    position: absolute;
    top: -1px;
    left: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 21px;
    font-weight: bold;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: 5;
    color: #1C2833;
    font-family: Sansation;
    backdrop-filter: blur(4px);
  }
  &--inactive {
    &:before {
      content: 'Offer Made';
    }
  }
  &--expired {
    &:before {
      color: rgb(192, 57, 43);
      content: 'Expired';
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: rgba(11, 53, 83, 0.07);

    ::v-deep .dropdown_menu {
      margin-left: 6px;
      background: transparent;
      &:hover {
        background: linear-gradient(0deg, rgba(11, 53, 83, 0.15), rgba(11, 53, 83, 0.15));
        &:before {
          width: 0;
        }
      }
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        left: 0;
        height: 20px;
        flex-shrink: 0;
        background-color: rgba(28, 40, 51, 0.2);
      }
    }
    ::v-deep .leads_status {
      border-radius: 0;
      border-top-left-radius: 2px;
      padding: 0 10px;
      height: calc(100% + 1px);
      top: -1px;
      left: -1px;
      margin-right: 3px;
    }
    .reject {
      color: $black;
      text-decoration: underline;
      transition-duration: 0.2s;
      span {
        color: $black;
      }
      &:hover {
        color: #C0392B;
        text-decoration: none;
        transition-duration: 0.2s;
        span {
          color: #C0392B;
        }
      }
    }
    .header {
      &__status {
        display: flex;
        align-items: center;
        padding: 0 10px;
        position: relative;
        top: -1px;
        left: -1px;
        border-top-left-radius: 2px;
        height: calc(100% + 1px);
        flex-shrink: 0;
        margin-right: 3px;
        font-weight: bold;
        font-size: 13px;
        p {
          margin-left: 5px;
        }
        span {
          opacity: 0.6;
        }
        background-color: #0B3553;
        color: white;
      }
      &__title {
        font-size: 15px;
        width: 100%;
        text-transform: uppercase;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: bold;
      }
      &__info {
        margin-left: 5px;
        font-size: 19px;
        flex-shrink: 0;
        color: rgba(11, 53, 83, 0.3);
        cursor: pointer;
        transition-duration: 0.2s;
        &:hover {
          color: #59B692;
        }
      }
    }
  }
  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 12px 10px 15px;
    .content {
      &__elements {
        color: rgba(28, 40, 51, 0.8);
        font-size: 13px;
        line-height: 140%;
        span {
          margin-right: 5px;
        }
        > div {
          padding-bottom: 7px;
          & > div {
            &:nth-child(2n+1) {
              // width: 140px;
              margin-right: 10px
            }
          }
        }
        .price {
          font-size: 15px;
          position: relative;
          top: -2px;
          font-weight: bold;
          color: #1C2833;
        }
      }
      &__button {
        margin-top: auto;
      }
    }
  }
}
@media (max-width: 768px) {
  .card {
    border: 0;
    box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
    max-width: 400px;
    &__header {
      ::v-deep .leads_status {
        top: 0px;
        left: 0px;
        height: 100%;
        margin-right: 10px;
      }
      .header {
        &__title {
          font-size: 13px;
        }
        &__status {
          top: 0px;
          left: 0px;
          height: 100%;
          margin-right: 10px;
        }
      }
    }
    &__content {
      padding: 12px 20px 15px;
    }
  }
}

</style>
