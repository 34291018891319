<template>
    <div class="settings-form">
        <div class="settings-form__title" v-if="data.title"> {{ data.title }} </div>
        <div class="settings-form__inputs">
            <template v-for="(item, index) in data.inputs" :key="`input-${index}`">
                <AddressAutocomplete
                    v-if="item.component && item.component == 'address-autocomplete'"
                    :key="`autocomplete-${index}`"
                    class="settings-form__input"
                    :style="item.style || ''"
                    :states="states"
                    :modelValue="item.value"
                    @updAddress="$emit('updAddress', $event)"
                />
                <StatesInput
                    v-if="item.component && item.component == 'address-state'"
                    :key="`autocomplete-${index}`"
                    class="settings-form__input"
                    :style="item.style || ''"
                    :modelValue="item.value"
                    :id="item.value_id"
                    @updStates="$emit('updStates', $event)"
                    @update:modelValue="$emit('updAddress', { state: $event })"
                />
                <InputGroup
                    v-if="!item.component"
                     :key="`autocomplete-${index}`"
                    class="settings-form__input"
                    :style="item.style || ''"
                    :data="item"
                    :modelValue="item.value"
                    @update:modelValue="updateRelatedKey(item.key,$event)"
                />
            </template>
            <div class="tw-text-xs tw-text-left tw-mb-4" v-if="!hideUpdateEmail">(If the email is incorrect, please <button type="button" @click="updateEmail" class="btn_link tw-text-blue-800">UPDATE it by clicking here</button> before sending the estimate.)</div>

        </div>
         <div class="error_text" v-if="showAddressError">* {{ typeof showAddressError === 'boolean'? 'Please fill all the fields': showAddressError}}</div>

        <!-- <Button v-if="data.btn" :data="data.btn" @click="$emit('onClick')"/> -->

    </div>
</template>
<script>
import InputGroup from './InputGroup'
import StatesInput from './StatesInput'
import Button from '../form/Button.vue'
import { useRouter } from 'vue-router'


import AddressAutocomplete from './AddressAutocomplete'
export default ({
    props: ['data', 'states','showAddressError','hideUpdateEmail'],
    components: {
        InputGroup,
        StatesInput,
        Button,
        AddressAutocomplete
    },
    setup(props, {emit}){
        const router = useRouter()
      function updateRelatedKey(key,value){
        let data = {}
        data[key] =value
        emit('updAddress',data)
      }

      function updateEmail(){
        router.push('/update-email-address')
      }

      return {
        updateRelatedKey,
        updateEmail,
      }
    }

})
</script>

<style scoped lang="scss">
.settings-form {
    &__title {
        font-size: 24px;
        line-height: 100%;
        text-transform: uppercase;
        color: #1C2833;
        width: 100%;
        text-align: left;
        font-weight: bold;
        margin-bottom: 8px;
    }
    &__inputs {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    &__input {
        margin-bottom: 10px;
    }
}
.error_text {
  text-align: left;
  padding-bottom: 10px;
  color: #db5151;
  font-weight: 700;
  font-size: 15px;
}

</style>
