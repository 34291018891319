export const wizardSteps = {
    type: { component: 'type', title: 'Select Type', help_topic_url: 'type', },
    windows_glass_style: { component: 'layout', title: 'Select WINDOW STYLE' },
    layout: { component: 'layout', title: 'Select SLIDING DOOR LAYOUT' },
    size: { component: 'size', title: 'Enter Size', buttons: [
        {
            type: 'outline',
            title: 'NEXT',
            event: 'next',
        }
    ] },
    style: { component: 'layout-price-from', title: 'Select Style', },
    grade: { component: 'layout-price-from', title: 'Select Grade', },
    option: { component: 'option', title: 'Select Option',  buttons: [
        {
            type: 'outline',
            title: 'NEXT',
            event: 'next',
        }
    ]},
   order_accessories: { component: 'option', title: 'Select Option',  buttons: [
      {
        type: 'outline',
        title: 'Save',
        event: 'save',
        icon: 'far fa-save',
        loading: false,
      }
    ]},
    location: { component: 'location', title: 'Select Location', className: 'overflow-hidden'},
    insert_colour: { component: 'color', title: 'Select Insert Color', className: 'overflow-hidden'},
    frame_colour: { component: 'color', title: 'Select Frame Color', className: 'overflow-hidden'},
    my_packages: {
        component: 'Packages',
        title: 'Select Package',
        className: 'overflow-hidden',
        buttons: [
            {
                type: 'outline',
                title: 'SKIP',
                event: 'skipPackage',
                showMe: ({isQuoting = false, allData}) => {
                    let allOption = allData.steps.find(op => op.name === 'All Options')

                    if(allData.history.length === 1 && allOption && allData.history[0] === allOption.id) {
                        return false
                    }
                    return true
                }
            }
        ]
    },
    all_options: { component: 'all-options', title: 'All Options', buttons: [
        {
            type: 'outline',
            title: 'Save',
            event: 'save',
            icon: 'far fa-save',
            loading: false
        },
        {
            type: 'outline',
            title: 'Save & Create New',
            event: 'saveNew',
            icon: 'far fa-plus-square',
            loading: false,
            hide_qp: true
        },
        {
            type: 'outline',
            title: 'Save & Clone',
            event: 'saveClone',
            icon: 'far fa-clone ',
            loading: false,
            hide_qp: true
        },
        //DO NOT REMOVE THIS - TEMPORARY HIDDEN
        // {
        //   type: 'outline',
        //   title: 'Save as Package',
        //   event: 'createPackage',
        //   icon: 'far fa-clone ',
        //   showMe: ({isQuoting = false, allData}) => {
        //     return !isQuoting
        //   }
        // }
    ]}
}

export const defaultStyleColors = [
    {
        "quoting_portal_id": 1,
        "setting": "PrimaryColour",
        "value": "#0E3753"
    },
    {
        "quoting_portal_id": 1,
        "setting": "PrimaryTextColour",
        "value": "#FFFFFF"
    },
    {
        "quoting_portal_id": 1,
        "setting": "SecondaryColour",
        "value": "#59b692"
    },
    {
        "quoting_portal_id": 1,
        "setting": "SecondaryTextColour",
        "value": "#FFFFFF"
    },
    {
        "quoting_portal_id": 1,
        "setting": "LayoutIconPrimary",
        "value": "#59b692"
    },
    {
        "quoting_portal_id": 1,
        "setting": "LayoutIconSecondary",
        "value": "#777777"
    },
    {
        "quoting_portal_id": 1,
        "setting": "PrimaryButtonColour",
        "value": "#345453"
    },
    {
        "quoting_portal_id": 1,
        "setting": "PrimaryButtonTextColour",
        "value": "#FFFFFF"
    }
]
